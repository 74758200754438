<template>
  <div class="col-md-10">
        <div class="m-2 my-4">
          <div class="card my-2">
           <div class="card-body">
            <h3 v-if="$store.state.users.name" class="mb-3">Welcome to {{$store.state.users.name}}</h3>
            <div class="row">
                <div class="col-md-6 my-2">
                    <div class="card">
                        <div class="card-body p-3">
                            <p class="m-0 p-0 pb-2"><strong>Last Payment History</strong></p>
                            <table class="table table-responsive-sm">
                                <tr>
                                    <th width="10%">SL</th>
                                    <th width="30%">Type</th>
                                    <th width="30%">Serial</th>
                                    <th width="30%">Amount</th>
                                </tr>
                                <tr v-for="(paymentHistory, id) in paymentHistorys" :key="id">
                                    <td>{{id+1}}</td>
                                    <td>{{paymentHistory?.payment_type}}</td>
                                    <td>{{paymentHistory?.serial?.name}}</td>
                                    <td>{{parseInt(paymentHistory?.amount).toLocaleString(undefined,{ minimumFractionDigits: 2 })}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 my-2">
                    <div class="card">
                        <div class="card-body p-3">
                            <p class="m-0 p-0 pb-2"><strong>Configuration</strong></p>
                            <table class="table table-responsive-sm">
                                <tr>
                                    <th width="20%">Serial</th>
                                    <th width="80%">
                                        <span>{{serials}}</span>
                                    </th>
                                </tr>
                                <tr>
                                    <th width="20%">Styles</th>
                                    <th width="80%">
                                        <span>{{styles}}</span>
                                    </th>
                                </tr>
                                <tr>
                                    <th width="20%">Colors</th>
                                    <th width="80%">
                                        <span>{{colors}}</span>
                                    </th>
                                </tr>
                                <tr>
                                    <th width="20%">Sizes</th>
                                    <th width="80%">
                                        <span>{{sizes}}</span>
                                    </th>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 my-2">

                    <div class="row">

                        <div class="col-md-2 my-2">
                            <div class="card">
                                <div class="card-body p-2">
                                    <p class="m-0 p-0 pb-4">Toal Serials</p>
                                    <h3 class="text-center pb-4">{{DataList?.data?.serials.length.toLocaleString(undefined,{ minimumFractionDigits: 0 })}}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 my-2">
                            <div class="card">
                                <div class="card-body p-2">
                                    <p class="m-0 p-0 pb-4">Toal Style</p>
                                    <h3 class="text-center pb-4">{{DataList?.data?.cloths.length.toLocaleString(undefined,{ minimumFractionDigits: 0 })}}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 my-2">
                            <div class="card">
                                <div class="card-body p-2">
                                    <p class="m-0 p-0 pb-4">Toal Colors</p>
                                    <h3 class="text-center pb-4">{{DataList?.data?.colors.length.toLocaleString(undefined,{ minimumFractionDigits: 0 })}}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 my-2">
                            <div class="card">
                                <div class="card-body p-2">
                                    <p class="m-0 p-0 pb-4">Toal Size</p>
                                    <h3 class="text-center pb-4">{{DataList?.data?.sizes.length.toLocaleString(undefined,{ minimumFractionDigits: 0 })}}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 my-2">
                            <div class="card">
                                <div class="card-body p-2">
                                    <p class="m-0 p-0 pb-4">Toal User</p>
                                    <h3 class="text-center pb-4">{{DataList?.data?.users.length.toLocaleString(undefined,{ minimumFractionDigits: 0 })}}</h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 my-2">
                            <div class="card">
                                <div class="card-body p-2">
                                    <p class="m-0 p-0 pb-4">Toal Payment</p>
                                    <h3 class="text-center pb-4">{{totalPayment.toLocaleString(undefined,{ minimumFractionDigits: 1 })}}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            </div>
          </div>
        </div>
  </div>
</template>

<script>

import myAxios from "../../service/axios.js";
export default {
  name: 'DashboardPage',
  data () {
    return {
        DataList : []
    }
  },
  computed: {
    totalPayment(){
        let subTotal = 0;
        this.DataList?.data?.payments.forEach((item) => {
            subTotal += parseInt(item.amount);
        });
        return subTotal;
    },
    paymentHistorys(){
        return this.DataList?.data?.payments.slice(0, 4);
    },
    serials(){
        return this.DataList?.data?.serials.map((item) => item.name).join(", ");
    },
    colors(){
        return this.DataList?.data?.colors.map((item) => item.name).join(", ");
    },
    styles(){
        return this.DataList?.data?.cloths.map((item) => item.name).join(", ");
    },
    sizes(){
        return this.DataList?.data?.sizes.map((item) => item.name).join(", ");
    }
  },
  methods : {
    async getDashboard(){
        try{
            await myAxios
            .get("dashboard",{
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem('accessToken')
                }
            })
            .then((response) => {
                this.DataList = response;
            })
        }catch(err){
            this.$store.commit('loadding', false, {root: true});
            console.log("Response: ", err);
            if(err.response.data.message == "Unauthenticated."){
                this.$store.context.commit("autologout");
            }
        }
    }
  },
  mounted(){
    this.getDashboard();
  },
  created(){
    document.title = "Dashboard List"
  }
}
</script>

<style lang="css" scoped>
  .dashboard .box .card {
  color: #fff;
  padding: 30px 24px;
  border-radius: 4px;
  position: relative;
}
.dashboard .box .card::before,
.dashboard .box .card::after {
  position: absolute;
  content: "";
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1411764706);
}
.dashboard .box .card::before {
  width: 200px;
  height: 200px;
  left: -20px;
}
.dashboard .box .card::after {
  width: 140px;
  height: 140px;
  bottom: -100px;
  right: 0;
}
.dashboard .box:nth-child(1) .card {
  background-color: #875fc0;
  background-image: linear-gradient(315deg, #875fc0 0%, #5346ba 74%);
}
.dashboard .box:nth-child(2) .card {
  background-color: #47c5f4;
  background-image: linear-gradient(315deg, #47c5f4 0%, #6791d9 74%);
}
.dashboard .box:nth-child(3) .card {
  background-color: #eb4786;
  background-image: linear-gradient(315deg, #eb4786 0%, #b854a6 74%);
}
.dashboard .box:nth-child(4) .card {
  background-color: #ffb72c;
  background-image: linear-gradient(315deg, #ffb72c 0%, #f57f59 74%);
}
.dashboard .box:nth-child(5) .card {
  background-color: #58b596;
  background-image: linear-gradient(72deg, #58b596 22%, #79debd 74%);
}
.dashboard .box:nth-child(6) .card {
  background-color: #ae513c;
  background-image: linear-gradient(72deg, #ae513c 22%, #e58069 74%);
}
.dashboard .box:nth-child(7) .card {
  background-color: #d1366f;
  background-image: linear-gradient(72deg, #d1366f 22%, #f06d9d 74%);
}
.dashboard .box:nth-child(8) .card {
  background-color: #aaa323;
  background-image: linear-gradient(72deg, #aaa323 22%, #e8e045 74%);
}
.dashboard .box:nth-child(9) .card {
  background-color: #aa7623;
  background-image: linear-gradient(72deg, #aa7623 22%, #efad43 74%);
}
.dashboard .box:nth-child(10) .card {
  background-color: #aa3c23;
  background-image: linear-gradient(72deg, #aa3c23 22%, #ea6a4d 74%);
}
.dashboard .box:nth-child(11) .card {
  background-color: #aa238b;
  background-image: linear-gradient(72deg, #aa238b 22%, #f044c8 74%);
}
.dashboard .box:nth-child(12) .card {
  background-color: #70aa23;
  background-image: linear-gradient(72deg, #70aa23 22%, #abf44c 74%);
}
</style>