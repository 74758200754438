<template>
  <nav class="navbar navbar-dark bg-dark navbar-expand-md sticky-top">
      <!-- <div class="container"> -->
        <button data-target="#myNav" data-toggle="collapse" class="navbar-toggler navbar-toggler-right"><span class="navbar-toggler-icon"></span></button>
        <div class="navbar-collapse collapse" id="myNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/" style="font-size: 20px;" class="nav-link">Welcome to admin dashboard</router-link>
            </li>
          </ul>
          <ul class="navbar-nav ml-auto">
            <li class="nav-item"></li>
            <li class="nav-item avatar">
                <div>
                  <img class="user-options-btn" :src="profile_avatar" alt="Avatar">
                </div>
                <div class="user-options">
                    <div class="head">
                        <div class="image">
                            <img :src="profile_avatar" alt="Avatar">
                        </div>
                        <div class="content">
                            <div class="name">{{$store?.state?.users?.name}}</div>
                            <div class="sub-title">{{$store?.state?.users?.user_type}}</div>
                        </div>
                    </div>
                    <div class="body">
                       <hr>
                        <router-link :to="{name : 'AdminProfile'}">
                            <span><i class="fa-regular fa-user"></i>Profile</span>
                        </router-link>
                        <hr>
                        <a href="#" @click.prevent="logout()"><i class="fa fa-sign-out" aria-hidden="true"></i> Logout</a>
                    </div>
                </div>
            </li>
          </ul>
        </div>
    </nav>
</template>

<script>
export default {
  name: 'HeaderPage',
  data () {
    return {
      base_url : '',  
    }
  },
  computed : {
    profile_avatar(){
      let avatar      = this.$store?.state?.users?.avatar;
      let avatarLink = "";
      if(avatar == null){
        avatarLink = this.base_url+'/images/user.png';
      }else{
        avatarLink = this.$store?.state?.users?.avatar;
      }
      return avatarLink;
    }
  },
  mounted(){
    this.base_url = window.location.origin;
    let user_options_btn = document.querySelector('.avatar .user-options-btn');
    let user_options = document.querySelector('.avatar .user-options');
    let user_options_head = document.querySelector('.avatar .user-options .head');
    window.addEventListener('mouseup',function(event){
        if(user_options_btn == event.target.closest(".avatar .user-options-btn")){
            user_options.classList.toggle('show');
        }else{
            if(event.target == user_options_head || event.target.closest(".user-options .head") == user_options_head){
                return false;
            }else if(event.target != user_options && event.target.parentNode != user_options){
                user_options.classList.remove("show");
            }
        }
    });

  },
  methods : {
    logout(){
      this.$store.dispatch("login/logout");
    },
    async getData(){
      try{
        this.$store.commit("loadding", true);
        this.axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('accessToken');
        await this.axios
        .get("user")
        .then((response) => {
          this.$store.commit("loadding", false);
          this.users = response.data;
        })
      }catch(err){
        this.$store.commit("loadding", false);
      }
    }
  }

  

}
</script>

<style lang="css">
.side-menu{}
.side-menu li{font-size: 18px;
    border-bottom: 1px solid #9db3c9;
    margin-top: 10px;
  }
.side-menu li:last-child{
  border-bottom: none;
}
.side-menu li a{color: #eedddd;}
.side-menu li i{margin-right: 10px;}
.side-menu li ul li{
  font-size: 18px;
  border-bottom: none;
  margin-top: 10px;
}
.side-menu li a:hover{
  color: #b39292;
}
.side-menu li a:hover{
  text-decoration: none;
}
.navbar-nav li a{
  font-size: 18px;
}
.navbar-nav li a i{
  font-size: 18px;
  margin-right: 5px;
}
</style>