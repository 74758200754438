<template>
  <div class="col-md-10">
        <div class="m-2 my-4">
          <div class="card my-2">
            <div class="card-body">
            <div style="overflow: hidden;">
              <h3 class="float-left">Create Package</h3>
              <h3 class="float-right">
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#insertModal">Add New</button>
              </h3>
            </div>
            <hr>
            <DataTable :data="dataList" :columns="columnss" class="table table-striped">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
            <template #column-3="props">
            <Button class="btn btn-primary btn-sm mr-2"
            @click="edit(props.rowData)"
            ><i class="fa fa-edit"></i></Button>
            <Button class="btn btn-danger btn-sm"
            @click="remove(props.rowData)"
            ><i class="fa fa-trash"></i></Button>
            </template>
            </DataTable>
            <div class="loader" v-if="$store.state.loadding"></div>
            </div>
          </div>
        </div>
  </div>



<!-- Insert modal -->
<div class="modal fade" id="insertModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Data</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">


      <form enctype="multipart/form-data" @submit.prevent="insert">


        <p class="text-center text-danger" v-if="$store?.state?.errors?.network_error">{{$store?.state?.errors?.network_error}}</p>

        <div class="row">
    
          <div class="col-md-12 my-2">
            <select class="form-control">
              <option value="">Select Shipping</option>
              <option :value="shipping.name" v-for="shipping in shippings" :key="shipping.name">{{shipping.name}}</option>
            </select>
          </div>


          <div class="col-md-12 m-2">

            <div class="row">

              <div class="col-md-12">

              <div class="row">
                  <div class="col-md-11 my-2">
                    <select class="form-control">
                      <option value="">Select Color</option>
                    </select>
                  </div>
                  <div class="col-md-1 my-2">
                    <button class="btn btn-danger" type="button" @click="addMoreRemove(item, id)"><i class="fa fa-trash"></i></button>
                  </div>

                  <div class="col-md-12">
                                
                              <div class="row mx-3 my-2">
                                  <div class="col-md-3">
                                    <select class="form-control">
                                      <option value="">Select Size</option>
                                    </select>
                                  </div>

                                  <div class="col-md-3">
                                    <input type="text" class="form-control" placeholder="QTY">
                                  </div>

                                  <div class="col-md-3">
                                    <input type="text" class="form-control" placeholder="Dojon">
                                  </div>

                                  <div class="col-md-2">
                                    <input type="text" class="form-control" placeholder="Price">
                                  </div>

                                  <div class="col-md-1">
                                    <button class="btn btn-dark" type="button" @click="addMoreRemove(item, id)"><i class="fa fa-trash"></i></button>
                                  </div>
                              </div>
                        <div class="col-md-12">
                          <div class="text-left my-1">
                            <button class="btn btn-dark btn-sm my-2" type="button" @click="addMore()">Item +</button>
                          </div>
                        </div>
                  </div>

                  <div class="col-md-12">
                      <div class="text-left">
                        <button class="btn btn-primary btn-sm my-2" type="button" @click="addMore()">Color +</button>
                      </div>
                  </div>
              </div>

            </div>



            </div>
          </div>
        </div>

        <div class="text-right">
          <TheButton :disabled="$store?.state?.loadding" :text="'Save Change'" :loading="$store.state.loadding"></TheButton>
        </div>
      </form>

      {{insertData}}

      <hr>




      {{shippings}}
      <hr>
      {{colors}}
      <hr>
      {{sizes}}
      </div>
    </div>
  </div>
</div>

</template>

<script>

import { onMounted } from 'vue';
import { mapState } from 'vuex';
import $ from 'jquery';
import 'bootstrap';

import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import TheButton from "../../helper/TheButton.vue";
import myAxios from "../../service/axios.js";
import  'datatables.net-bs4';
DataTable.use(DataTablesCore);


let updateModal = null;
let insertModal = null;


export default {
  setup(){
    onMounted(()=>{
      updateModal = $("#updateModal");
      insertModal = $("#insertModal");
    })
  },
  data() {
      return {
        columnss : [
            {data : null, render : function(data, type, row, meta){ return `${meta.row + 1}`}},
            {data : "name"},
            {data : null, render : function(data){
              if(data.status == 1){
                return `<span class="badge bg-success">Active</span>`;
              }else{
                return `<span class="badge bg-danger p-2">Inactive</span>`;
              }
            }},
        ],
        shippings   : [],
        colors      : [],
        sizes       : [],
        insertData  : [
          {shipping_id : '', color_id : ''},
          // {shipping_id : '', color_id : ''}
        ],
        updateData  : {id : '',name : '', avatar : '', address : '',status : ''}
      }
  },
  computed : {
    ...mapState('shipping', {
      dataList : state => state.dataList
    })
  },
  methods: {
    async shippinglist(){
      try{
        await myAxios
        .get("admin/getShipping", {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem('accessToken')
          }
        })
        .then((response) => {
          this.shippings = response.data.data
        })
      }catch(err){
        console.log(err)
      }
    },
    async colorlist(){
      try{
        await myAxios
        .get("admin/getColor", {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem('accessToken')
          }
        })
        .then((response) => {
          this.colors = response.data.data
        })
      }catch(err){
        console.log(err)
      }
    },    
    async sizelist(){
      try{
        await myAxios
        .get("admin/getSize", {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem('accessToken')
          }
        })
        .then((response) => {
          this.sizes = response.data.data
        })
      }catch(err){
        console.log(err)
      }
    },
    addMore(){
      this.insertData.push({name : ''});
    },
    insert(){
      this.$store.dispatch("shipping/insert", {data : this.insertData, myModal : insertModal});
      this.insertData = [{name : ''}];
    },
    edit(data){
      updateModal.modal("show");
      this.updateData.id      = data.id;
      this.updateData.name    = data.name;
      this.updateData.status  = data.status;
    },
    update() {
      this.$store.dispatch("shipping/update", {data : this.updateData, myModal : updateModal});
      this.updateData.avatar  = "";
    },
    remove(data) {
      if(confirm("Are you sure delete?")){
        this.$store.dispatch("shipping/remove", data);
      }
    },
    addMoreRemove(remove, index){
      this.insertData.splice(index, 1);
    },
  },
  mounted(){
    this.shippinglist();
    this.colorlist();
    this.sizelist();
    this.$store.dispatch("shipping/getData");
  },
  components : {
    DataTable,
    TheButton
  },
  created(){
    document.title = "Shipping Create"
  }
};
</script>
