<template>
	<div class="col-md-10">
        <div class="m-2 my-4">
          <div class="card my-2">
            <div class="card-body">
            <div style="overflow: hidden;">
              <h3 class="float-left">Shipment Details</h3>
							<h3 class="float-right">
								<button class="btn btn-primary" type="button" @click="print">Print</button>
							</h3>
            </div>
            <hr>
            <h5 class="my-4">Serial No: {{shipmentDatas?.data?.name}}</h5>
					<table class="table table-striped table-sm-responsive table-bordered">
						<tr>
							<th colspan="3">Information</th>
							<th>Total Quantity</th>
							<th>Total Value</th>
						</tr>
						<tr v-for="(shipmentData, id) in shipmentDatas?.data?.manage_clothing" :key="id">
							<td colspan="3"><b>{{shipmentData.clothing.name}}</b> <b>total value and quantity</b></td>
							<td><b>{{shipmentData.qty}}</b></td>
							<td><b>{{parseFloat(shipmentData.total).toLocaleString(undefined,{ minimumFractionDigits: 2 })}}</b></td>
						</tr>
						<tr>
							<td colspan="3" align="right"><b>Total</b></td>
							<td><b>{{shipmentDatas?.qty}}</b></td>
							<td><b>{{parseFloat(shipmentDatas?.total).toLocaleString(undefined,{ minimumFractionDigits: 2 })}}</b></td>
						</tr>
						<tr v-for="(payment, id) in payments" :key="id">
							<td>
								<p class="m-0 p-0" align="center"><b>{{payment.date}}</b></p>
								<p class="m-0 p-0" align="center"><b>{{payment.date}}</b></p>
							</td>
							<td>
								<p class="m-0 p-0"><b>{{payment.payment_type}}</b></p>
								<p class="m-0 p-0" align="right"><b>Balance</b></p>
							</td>
							<td>
								<p class="m-0 p-0" align="center"><b>{{payment.qty_details}}</b></p>
							</td>
							<td>
								<p class="m-0 p-0" align="right"><b>{{parseFloat(payment.paid_amount).toLocaleString(undefined,{ minimumFractionDigits: 2 })}}</b></p>
								<p class="m-0 p-0" align="left"><strong>
									<span v-if="payment.balance == 0">Nil</span>
									<span v-else>{{payment.balance.toLocaleString(undefined,{ minimumFractionDigits: 2 })}}</span>
								</strong></p>
							</td>
							<td></td>
						</tr>
					</table>
            </div>
          </div>
          <PaymentPrint v-show="false" :shipmentDatas="shipmentDatas" :payments="payments"  ref="paymentPrintArea"></PaymentPrint>
        </div>
  </div>

</template>
<script>
import myAxios from "../../service/axios.js";
import PaymentPrint from './print/PaymentPrint.vue';
	export default {
	data() {
		return {
			shipmentDatas : [],
			payments 			: []
		}
	},
  methods: {
		async getData(){
			try{
				await myAxios
				.get("admin/getProcessing", {
					headers : {
					"Authorization" : "Bearer "+localStorage.getItem('accessToken')
					},
					params : {
						slug : this.$route.params.slug
					}
				})
				.then((response) => {

				let total = 0;
				let qty  	= 0;

				response.data.manage_clothing.map((data) => {
						qty     += data.qty;
						total 	+= parseFloat(data.total);
				});

				this.shipmentDatas =  {
					data 	: response.data,
					total : total,
					qty 	: qty
				}

				var incrementalTotal 	= 0;
				response.data.payments.forEach((item) => {
					incrementalTotal += parseFloat(item.amount);
					this.payments.push({
						date 					: item.date,
						payment_type 	: item.payment_type,
						qty_details 	: item.qty_details,
						paid_amount 	: item.amount,
						balance 			: total - incrementalTotal
					});
				});


				})
			}catch(err){
				console.log(err)
			}
		},
		print(){
			const w = window.open("", "_blank", "width=1000, height=650");
			w.document.write(this.$refs.paymentPrintArea.$el.innerHTML);
			w.document.close();
			w.print();
		}
  },
	mounted(){
		this.getData();
	},
	created(){
    document.title = "Shipment Details"
  },
	components : {
		PaymentPrint
	}
};
</script>