<template>
  <div class="col-md-10">
        <div class="m-2 my-4">
          <div class="card my-2">
            <div class="card-body">
            <div style="overflow: hidden;">
              <h3 class="float-left">Admin List</h3>
              <h3 class="float-right">
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#insertModal">Add New</button>
              </h3>
            </div>
            <hr>

            <DataTable :data="userlist" :columns="columnss" class="table table-responsive-sm table-striped">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
            <template #column-5="props">

              <span v-if="user_id != props?.rowData?.id" style="cursor: pointer;">
                <span v-if="props?.rowData?.status == 1" @click="activeInactive(props.rowData)" class="badge bg-success">Active</span>
                <span v-else class="badge bg-danger p-2" @click="activeInactive(props.rowData)">Inactive</span>
              </span>

            </template>

            <template #column-1="props">
              <img v-if="props?.rowData?.avatar == null" :src="require(`@/assets/user.png`)" style="height: 50px;">
              <img v-else :src="props.rowData.avatar" class="image" alt="Image" style="height: 50px;">
            </template>

            </DataTable>
            <div class="loader" v-if="$store.state.loadding"></div>
            </div>
          </div>
        </div>
  </div>


<!-- Insert modal -->
<div class="modal fade" id="insertModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Data</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">

      <ul class="list-unstyled">
        <li class="text-danger" v-for="(error,id) in errors" :key="id">{{error[0]}}</li>
      </ul>
      
      <form enctype="multipart/form-data" @submit.prevent="insert">
        <p class="text-center text-danger" v-if="$store?.state?.errors?.network_error">{{$store?.state?.errors?.network_error}}</p>
        <div class="row">

          <div class="col-md-12 mb-2">
            <input type="text" class="form-control" v-model="insertData.name" placeholder="Name" required>
          </div>

          <div class="col-md-12 mb-2">
            <input type="email" class="form-control" v-model="insertData.email" placeholder="E-mail" required>
          </div>

          <div class="col-md-12 mb-2">
            <input type="password" class="form-control" v-model="insertData.password" placeholder="Password" required>
          </div>

        </div>
        <TheButton :disabled="$store?.state?.loadding" :text="'Save Change'" :loading="$store.state.loadding"></TheButton>
      </form>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import { onMounted } from 'vue';
import { mapState } from 'vuex';
import $ from 'jquery';
import 'bootstrap';

import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import TheButton from "../../helper/TheButton.vue";
import  'datatables.net-bs4';
DataTable.use(DataTablesCore);

let insertModal = null;

export default {
  setup(){
    onMounted(()=>{
      insertModal = $("#insertModal");
    })
  },
  data() {
      return {
        columnss : [
            {data : null, render : function(data, type, row, meta){ return `${meta.row + 1}`}},
            {data : "avatar"},
            {data : "name"},
            {data : "email"},
            {data : "user_type"},
            {data : "status"},
        ],
        user_id : atob(atob(localStorage.getItem('user_id'))),
        insertData : {name: '',email:'', password: ''},
        errors : []
      }
  },
  computed : {
    ...mapState('usertype', {
      userlist : state => state.userlist
    })
  },
  methods: {
    activeInactive(data){
      this.$store.dispatch("usertype/activeInactive", data);
    },
    async insert(){
      try{
        await this.$store.dispatch("usertype/insert", {data : this.insertData, myModal : insertModal});
        this.insertData = {name: '',email:'', password: ''};
        this.errors     = [];
      }catch(error){
        this.errors = error;
      }
    }
  },
  mounted(){
    this.$store.dispatch("usertype/getAdmin");
  },
  components : {
    DataTable,
    TheButton
  },
  created(){
    document.title = "Admin List"
  }
};
</script>