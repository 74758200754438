<template>
	<div class="col-md-10">
        <div class="m-2 my-4">
          <div class="card my-2">
            <div class="card-body">
            <div style="overflow: hidden;">
              <h3 class="float-left">Size List</h3>
							<h3 class="float-right">
								<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#insertModal">Add New</button>
							</h3>
            </div>
            <hr>
						<DataTable :data="dataList" :columns="columnss" class="table table-responsive-sm table-striped">
							<thead>
								<tr>
									<th>SL</th>
									<th>Name</th>
									<th>Status</th>
									<th>Action</th>
								</tr>
							</thead>
						<template #column-3="props">
						<Button class="btn btn-primary btn-sm mr-2"
						@click="edit(props.rowData)"
						><i class="fa fa-edit"></i></Button>
						<Button class="btn btn-danger btn-sm"
						@click="remove(props.rowData)"
						><i class="fa fa-trash"></i></Button>
						</template>
						</DataTable>
						<div class="loader" v-if="$store.state.loadding"></div>
            </div>
          </div>
        </div>
  </div>



<!-- Insert modal -->
<div class="modal fade" id="insertModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Data</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
      <ul class="list-unstyled">
				<li class="text-danger" v-for="(error,id) in errors" :key="id">{{error[0]}}</li>
			</ul>
      <form enctype="multipart/form-data" @submit.prevent="insert">
				<p class="text-center text-danger" v-if="$store?.state?.errors?.network_error">{{$store?.state?.errors?.network_error}}</p>
				<div class="row" v-for="(item, id) in insertData" :key="id">
          <div class="col-md-11 my-2">
            <input type="text" name="name" class="form-control" v-model="item.name" placeholder="Size name" required>
          </div>
          <div class="col-md-1 my-2" v-if="id != 0">
            <button class="btn btn-danger" type="button" @click="addMoreRemove(item, id)"><i class="fa fa-trash"></i></button>
          </div>
        </div>
				<div class="text-left">
					<button class="btn btn-success" type="button" @click="addMore()">More +</button>
				</div>
				<div class="text-right">
					<TheButton :disabled="$store?.state?.loadding" :text="'Save Change'" :loading="$store.state.loadding"></TheButton>
				</div>
      </form>
      </div>
    </div>
  </div>
</div>


<!-- Update modal -->
<div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
	<div class="modal-dialog modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Update Data</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body">
			<form enctype="multipart/form-data" @submit.prevent="update">
				<p class="text-center text-danger" v-if="$store?.state?.errors?.network_error">{{$store.state.errors.network_error}}</p>
				<div class="form-group">
					<input type="text" class="form-control" v-model="updateData.name" placeholder="Size name">
					<small class="form-text" v-if="$store?.state?.errors?.name">
						{{$store.state.errors.name[0]}}
					</small>
				</div>
				<div class="form-group">
					<select name="status" class="form-control" v-model="updateData.status">
						<option value="1">Active</option>
						<option value="0">Inactive</option>
					</select>
					<small class="form-text" id="errstatus"></small>
				</div>
				<TheButton :disabled="$store?.state?.loadding" :text="'Save Change'" :loading="$store.state.loadding"></TheButton>
			</form>
			</div>
		</div>
	</div>
</div>

</template>

<script>

import { onMounted } from 'vue';
import { mapState } from 'vuex';
import $ from 'jquery';
import 'bootstrap';

import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import TheButton from "../../helper/TheButton.vue";
import  'datatables.net-bs4';
DataTable.use(DataTablesCore);


let updateModal = null;
let insertModal = null;


export default {
	setup(){
		onMounted(()=>{
			updateModal = $("#updateModal");
			insertModal = $("#insertModal");
		})
	},
  data() {
			return {
				columnss : [
						{data : null, render : function(data, type, row, meta){ return `${meta.row + 1}`}},
						{data : "name"},
						{data : null, render : function(data){
							if(data.status == 1){
								return `<span class="badge bg-success">Active</span>`;
							}else{
								return `<span class="badge bg-danger p-2">Inactive</span>`;
							}
						}},
				],
				errors : [],
				insertData : [{name : ''}],
				updateData : {id : '', name : '', status : ''}
			}
  },
	computed : {
		...mapState('size', {
			dataList : state => state.dataList
		})
	},
  methods: {
		addMore(){
			this.insertData.push({name : ''});
		},
		async insert(){
			try{
				await this.$store.dispatch("size/insert", {data : this.insertData, myModal : insertModal});
				this.insertData = [{name : ''}];
			}catch(error){
				this.errors = error;
			}
		},
		edit(data){
			updateModal.modal("show");
			this.updateData.id 			= data.id;
			this.updateData.name 		= data.name;
			this.updateData.status 	= data.status;
		},
		update() {
			this.$store.dispatch("size/update", {data : this.updateData, myModal : updateModal});
			this.updateData.avatar 	= "";
		},
		remove(data) {
			if(confirm("Are you sure delete?")){
				this.$store.dispatch("size/remove", data);
			}
		},
		addMoreRemove(remove, index){
			this.insertData.splice(index, 1);
		},
  },
	mounted(){
		this.$store.dispatch("size/getData");
	},
	components : {
		DataTable,
		TheButton
	},
	created(){
    document.title = "Size List"
  }
};
</script>