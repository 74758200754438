<template>
  <div class="col-md-10">
        <div class="m-2 my-4">
          <div class="card my-2">
            <div class="card-body">
            <div style="overflow: hidden;">
              <h3 class="float-left">Payment History</h3>
              <h3 class="float-right">
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#insertModal">Make Payment</button>
              </h3>
            </div>
            <hr>

            <DataTable :data="dataList" :columns="columnss" class="table table-striped table-responsive-sm">
              <thead>
                <tr>
                  <th width="5%">SL</th>
                  <th width="10%">Date</th>
                  <th width="10%">Serial</th>
                  <th width="15%">Payment Details</th>
                  <th width="10%">TT</th>
                  <th width="10%">Amount</th>
                  <th width="10%">Action</th>
                </tr>
              </thead>
            <template #column-6="props">
            <!-- <Button class="btn btn-primary btn-sm mr-2"
            @click="edit(props.rowData)"
            ><i class="fa fa-edit"></i></Button> -->
            <Button class="btn btn-danger btn-sm"
            @click="remove(props.rowData)"
            ><i class="fa fa-trash"></i></Button>
            </template>
            </DataTable>
            <div class="loader" v-if="$store.state.loadding"></div>
            </div>
          </div>
        </div>
  </div>





<!-- Insert modal -->
<div class="modal fade" id="insertModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Data</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
      <form enctype="multipart/form-data" @submit.prevent="insert">
        <p class="text-center text-danger" v-if="$store?.state?.errors?.network_error">{{$store?.state?.errors?.network_error}}</p>

        <div class="row">
          <div class="col-md-12 my-2">
            <select class="form-control" @change="getSelectSerialData" required v-model="insertData.serial_id">
              <option value="">Select Serial</option>
              <option :value="serial.id" v-for="serial in serials" :key="serial.id">{{serial.name}}</option>
            </select>
          </div>
          <div class="col-md-12 ">
            <div class="row">
              <div class="col-md-12 my-2">
                <input type="date" required v-model="insertData.date" class="form-control">
              </div>
              <div class="col-md-4 my-2">
                <input type="text" v-model="insertData.payment_type" required class="form-control" placeholder="Payment Type">
              </div>
              <div class="col-md-4 my-2">
                <input type="text" v-model="insertData.qty_details" class="form-control" placeholder="1000 X 20">
              </div>
              <div class="col-md-4 my-2">
                <input type="number" v-model="insertData.amount" min="0" :max="dueAmount" setp="0.1" class="form-control" placeholder="Amount" required>
                <small>Current due: {{dueAmount}}</small>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <TheButton :disabled="$store?.state?.loadding" :text="'Submit'" :loading="$store.state.loadding"></TheButton>
        </div>
      </form>
      </div>
    </div>
  </div>
</div>




<!-- Update modal -->
<div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Update Data</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
      <form enctype="multipart/form-data" @submit.prevent="update">
        <p class="text-center text-danger" v-if="$store?.state?.errors?.network_error">{{$store.state.errors.network_error}}</p>

        <div class="row">
          <div class="col-md-12 my-2">
            <select class="form-control" @change="getSelectSerialData" required v-model="updateData.serial_id">
              <option value="">Select Serial</option>
              <option :value="serial.id" v-for="serial in serials" :key="serial.id">{{serial.name}}</option>
            </select>
          </div>
          <div class="col-md-12 ">
            <div class="row">
              <div class="col-md-12 my-2">
                <input type="date" required v-model="updateData.date" class="form-control">
              </div>
              <div class="col-md-4 my-2">
                <input type="text" v-model="updateData.payment_type" required class="form-control" placeholder="Payment Type">
              </div>
              <div class="col-md-4 my-2">
                <input type="text" v-model="updateData.qty_details" class="form-control" placeholder="1000 X 20">
              </div>
              <div class="col-md-4 my-2">
                <input type="number" v-model="updateData.amount" min="0" :max="dueAmount" setp="0.1" class="form-control" placeholder="Amount" required>
                <small>Current due: {{dueAmount}}</small>
              </div>
            </div>
          </div>
        </div>
        <TheButton :disabled="$store?.state?.loadding" :text="'Save Change'" :loading="$store.state.loadding"></TheButton>
      </form>
      </div>
    </div>
  </div>
</div>

</template>

<script>

import { onMounted } from 'vue';
import { mapState } from 'vuex';
import $ from 'jquery';
import 'bootstrap';
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import TheButton from "../../helper/TheButton.vue";
import myAxios from "../../service/axios.js";
import  'datatables.net-bs4';
DataTable.use(DataTablesCore);


let updateModal = null;
let insertModal = null;


export default {
  setup(){
    onMounted(()=>{
      updateModal = $("#updateModal");
      insertModal = $("#insertModal");
    })
  },
  data() {
      return {
        columnss : [
            {data : null, render : function(data, type, row, meta){ return `${meta.row + 1}`}},
            {data : "date"},
            {data : "serial.name"},
            {data : "payment_type"},
            {data : "qty_details"},
            {data : null, render : function(data){ return parseInt(data.amount).toLocaleString(undefined,{ minimumFractionDigits: 2 })}},
        ],
        serials    : [],
        insertData : {serial_id: "", date : "", payment_type : "", qty_details: "", amount : ""},
        updateData : {id: "", serial_id: "", date : "", payment_type : "", qty_details: "", amount : ""},
        dueAmount : ''
      }
  },
  computed : {
    ...mapState('payment', {
      dataList : state => state.dataList
    })
  },
  methods: {
    async serialList(){
      try{
        await myAxios
        .get("admin/getSerial", {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem('accessToken')
          }
        })
        .then((response) => {
          this.serials = response.data.data
        })
      }catch(err){
        console.log(err)
      }
    },
    async getSelectSerialData(e, para = null){


      let id = '';
      if(para == null){
        id = e.target.value;
      }else{
        id = e;
      }

      try{
        await myAxios
        .get("admin/getProcessing", {
          headers : {
          "Authorization" : "Bearer "+localStorage.getItem('accessToken')
          },
          params : {
            serial_id : id
          }
        })
        .then((response) => {
          let total   = 0;
          let payment = 0;
          response.data.manage_clothing.map((data) => {
            total     += parseInt(data.total);
          });
          response.data.payments.forEach((item) => {
            payment += parseInt(item.amount);
          });
          this.dueAmount = total - payment;
        })
      }catch(err){
        console.log(err)
      }


    },
    addMore(){
      this.insertData.push({name : ''});
    },
    insert(){
      this.$store.dispatch("payment/insert", {data : this.insertData, myModal : insertModal});
      this.insertData = {serial_id: "", date : "", payment_type : "", qty_details: "", amount : ""};
      this.dueAmount = '';
    },
    edit(data){
      updateModal.modal("show");
      this.dueAmount                  = '';
      this.updateData.id              = data.id;
      this.updateData.serial_id       = data.serial_id;
      this.updateData.date            = data.date;
      this.updateData.payment_type    = data.payment_type;
      this.updateData.qty_details     = data.qty_details;
      this.updateData.amount          = data.amount;
      this.getSelectSerialData(data.serial_id, 1);
    },
    update() {
      this.$store.dispatch("payment/update", {data : this.updateData, myModal : updateModal});
      this.updateData.avatar  = "";
    },
    remove(data) {
      if(confirm("Are you sure delete?")){
        this.$store.dispatch("payment/remove", data);
      }
    },
    addMoreRemove(remove, index){
      this.insertData.splice(index, 1);
    },
  },
  mounted(){
    this.serialList();
    this.$store.dispatch("payment/getData");
  },
  components : {
    DataTable,
    TheButton
  },
  created(){
    document.title = "Payment List"
  }
};
</script>