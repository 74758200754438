<template>
  <div style="background: #ddd;">
  <section class="my-5 p-3 py-5" style="max-width: 450px;margin: auto;">
      <div class="card">
        <div class="card-body">
          <form class="py-4" @submit.prevent="signup" autocomplete="off" v-if="validCheck.status">
            <hr>
            <div class="form-group">
              <input type="password" required class="form-control" placeholder="New Password" v-model="myData.password">
              <small v-if="$store?.state?.errors?.email" class="form-text" style="color:red;">{{$store.state.errors.email[0]}}</small>
            </div>
            <TheButton :block="true" :disabled="$store?.state?.loadding" :loading="$store.state.loadding" text="Submit" :color="'dark'"></TheButton>
          </form>
          <h4 v-else class="text-center py-5 my-5">{{validCheck.msg}}</h4>
          <hr>
          <small class="d-block text-center">&copy; Enrich IT Solutions</small>
          {{singUpMsg}}
        </div>
      </div>
    </section>
  </div>
</template>
<script>

import TheButton from "../../helper/TheButton.vue";

export default {

  name: 'LoginPage',
  data () {
    return {
      myData : {email    : "", encrypt  : "", password : ""},
      query : {f : '', s : ''},
      validCheck : '',
      singUpMsg  : ''
    }
  },
  methods : {
    async signup(){
      try{
        let response = await this.$store.dispatch("passwordRecovery/update", this.myData);
        if(response.status){
          this.toastr.success(response.msg);
          this.$router.push("/login");
        }
        this.singUpMsg = response;
      }catch(error){
        this.singUpMsg = error;
      }
    },
    async linkCheck(){
      try{
        this.validCheck = await this.$store.dispatch("passwordRecovery/linkcheck", this.query);
      }catch(error){
        this.validCheck = error;
      } 
    }
  },
  mounted(){
    this.query.f        = this.$route.query.f;
    this.query.s        = this.$route.query.s;
    this.myData.email   = this.$route.query.f;
    this.myData.encrypt = this.$route.query.s;
    this.linkCheck();
  },
  created(){
    window.document.title = "Change password"
  },
  components : {
    TheButton
  }


}
</script>

<style lang="css" scoped>
  body{background: #ddd;}
    input[type="text"], input[type="password"]{
    border: none;
    border-bottom: 1.5px solid #e2e5e8;
    border-radius: 0;
  }
  .form-control:focus {
    color: none;
    background-color: none;
    border-color: none;
    outline: none;
    box-shadow: none;
  }
</style>