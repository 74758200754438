<template>
	<div style="background: #ddd;">
	<section class="my-5 p-3 py-5" style="max-width: 450px;margin: auto;">
      <div class="card">
        <div class="card-body">
          <form class="py-4 my-5" @submit.prevent="getPassword" autocomplete="off">
            <hr>
            <div class="form-group">
              <input type="text" v-model="myData.email" class="form-control" placeholder="E-mail address" required>
              <small v-if="$store?.state?.errors?.email" class="form-text" style="color:red;">{{$store.state.errors.email[0]}}</small>
            </div>
            <TheButton :block="true" :disabled="$store?.state?.loadding" :loading="$store.state.loadding" :color="'dark'" text="Submit"></TheButton>
            <router-link class="text-center d-block my-2" :to="{name : 'login'}">Login to email</router-link>
          </form>
          <small class="d-block text-center">&copy; Enrich IT Solutions</small>
        </div>
      </div>
    </section>
  </div>
</template>
<script>

import TheButton from "../../helper/TheButton.vue";

export default {
	data () {
		return {
			myData : {
				email : '',
				url   : ''
			}
		}
	},
	methods : {
		async getPassword(){
				let response = await this.$store.dispatch("passwordRecovery/insert", this.myData);
				if(response.status == null){
					this.toastr.error(response?.errors)
				}else if(response.status == true){
					this.toastr.success("Please check your email.");
					this.myData.email = '';
				}else if(response.status == false){
					this.toastr.error("Something went wrong");
				}
		}
	},
	mounted(){
		this.myData.url = window.location.origin;
	},
	created(){
		window.document.title = "Forgot Password"
	},
	components : {
		TheButton
	}


}
</script>

<style lang="css" scoped>
	body{background: #ddd;}
		input[type="text"], input[type="password"]{
		border: none;
		border-bottom: 1.5px solid #e2e5e8;
		border-radius: 0;
	}
	.form-control:focus {
		color: none;
		background-color: none;
		border-color: none;
		outline: none;
		box-shadow: none;
	}
</style>