<template>
	<div>
			<div style="margin: 0 auto;text-align:center">
				<h2 style="font-size: 24px; margin: 5px;">{{$store?.state?.siteData?.title}}</h2>
				<h4 style="font-size: 18px;margin: 5px;padding-bottom: 5px;">{{$store?.state?.siteData?.address}}</h4>
				<h4 style="font-size: 18px;margin: 5px;padding-bottom: 5px;">Phone: {{$store?.state?.siteData?.phone}}, Email: {{$store?.state?.siteData?.email}}</h4>
			</div>

       <h2 class="float-left">{{finalData?.clothing?.name}}</h2>

			<div style="margin-left: 5px;" v-for="mc in finalData.manage_color" :key="mc">

				<h3 style="margin: 10px 0 5px 0;">{{mc.color.name}}</h3>
				<table style="width: 100%" border="0" cellpadding="10" cellspacing="0">
					<tr>
						<th width="80%" style="padding:0; margin:0;">
							<table style="width: 100%" border="0" cellpadding="10" cellspacing="0">
								<tr>
									<th v-for="ms in mc.manage_size" :key="ms" style="padding:0; margin: 0;">
										
										<p align="center" style="padding: 0; margin: 0;background: rgb(99 138 199);color: rgb(36 25 29);">{{ms.size.name}}</p>

										<p align="center" style="padding: 0; margin: 0;background: rgb(195 183 203);color: rgb(36 25 29);">{{ms.qty}} x {{(ms.total / ms.qty).toLocaleString(undefined,{ minimumFractionDigits: 1 })}}</p>

										<p align="center" style="padding: 0; margin: 0;background: rgb(225 206 210);color: rgb(36 25 29);">{{ms.total}}</p>

									</th>
								</tr>
							</table>
						</th>
						<th width="10%" style="padding: 0px;margin: 0;vertical-align: top;">
							<p align="center" style="padding: 0; margin: 0;background: rgb(99 138 199);color: rgb(36 25 29);">Total Value</p>
							<p align="center" style="padding: 0; margin: 0;background: rgb(195 183 203);color: rgb(36 25 29);">{{mc.total}}</p>
						</th>
						<th width="10%" style="padding: 0px;margin: 0;vertical-align: top;">
							<p align="center" style="padding: 0; margin: 0;background: rgb(99 138 199);color: rgb(36 25 29);">Total Qty</p>
							<p align="center" style="padding: 0; margin: 0;background: rgb(195 183 203);color: rgb(36 25 29);">{{mc.qty}}</p>
						</th>
					</tr>
				</table>
			</div>

		<div style="margin-left: 5px;" v-if="finalData?.total">
				<table style="width: 100%; margin-top: 5px;" border="0" cellpadding="10" cellspacing="0">
					<tr style="background: #cdcdcd;color: #7c3838;">
						<th width="80%">
							<p align="right" class="m-0 p-0">Total</p>
						</th>
						<th width="10%">
							<p align="center" class="m-0 p-0">{{finalData.total}}</p>
						</th>
						<th width="10%">
							<p align="center" class="m-0 p-0">{{finalData.qty}}</p>
						</th>
					</tr>
				</table>
		</div>
	</div>
</template>

<script>
export default {

  name: 'StylePrint',

  data () {
    return {}
  },
  props : ['finalData']
}
</script>

<style lang="css">
    @media print {
        header, footer, aside, form, … {
            display: none;
        }
			footer{
				page-break-after: always;
			}
    }

    @page {
      size: landscape;
    }

    @page:first {
        margin: 1cm;
    }

    @page:last {
        margin: 10px;
    }

    @page:left {
        margin: 2cm 1.5cm 2cm 2cm;
    }

    @page:right {
        margin: 2cm 2cm 2cm 1.5cm;
    }

    @media print {
      a:after {
        content: "("attr(href)")";
      }
    }

    td{
        vertical-align: middle;
    }
</style>