<template>
  <div class="col-md-10">
        <div class="m-2 my-4">
          <div class="card my-2">
            <div class="card-body">
            <div class="row">
              <div class="col-md-12">

      <form enctype="multipart/form-data" @submit.prevent="insert">
        <p class="text-center text-danger" v-if="$store?.state?.errors?.network_error">{{$store?.state?.errors?.network_error}}</p>


      <div class="row">

        <div class="col-md-12 my-2">
          <select class="form-control" v-model="insertData.serial_id" required>
            <option value="">Select Serial</option>
            <option :value="serial.id" v-for="serial in serials" :key="serial.id">{{serial.name}}</option>
          </select>
        </div>        

        <div class="col-md-12 my-2">
          <select class="form-control" v-model="insertData.clothing_id" required>
            <option value="">Select Style</option>
            <option :value="cloth.id" v-for="cloth in cloths" :key="cloth.id">{{cloth.name}}</option>
          </select>
        </div>


        <div class="col-md-12">
            <div class="row">
                <div class="col-md-12 my-2" v-for="(colorData, id) in insertData.colors" :key="id">
                  <div class="row">

                    <div class="col-md-11 my-2">
                      <select class="form-control" v-model="colorData.color_id" required>
                        <option value="">Select Colors</option>
                        <option :value="color.id" v-for="color in colors" :key="color.id">{{color.name}}</option>
                      </select>
                    </div>
                    <div class="col-md-1 my-2">
                      <button v-if="id != 0" class="btn btn-danger" type="button" @click="addMoreRemove(insertData, insertData.colors, id)"><i class="fa fa-trash"></i></button>
                    </div>



                    <div class="col-md-12 my-2 mx-3">
                      <div class="row my-2">
                        <div class="col-md-9">
                          <b>Item Information</b>
                          <hr>
                        </div>
                        <div class="col-md-1">
                          <b>QTY</b>
                          <hr>
                        </div>
                        <div class="col-md-2">
                          <b>Price (TK)</b>
                          <hr>
                        </div>
                      </div>

                      <div class="row my-2" v-for="(size, id) in colorData.sizes" :key="id">

                        <div class="col-md-2 my-2">
                          <select class="form-control" v-model="size.size_id" required>
                            <option value="">Size</option>
                            <option :value="size.id" v-for="size in sizes" :key="size.id">{{size.name}}</option>
                          </select>
                        </div>
                        <div class="col-md-2 my-2">
                         <input type="number" v-model="size.box" min="1" @change="ItemUpdate(insertData, colorData, size)" class="form-control" placeholder="Box" required>
                        </div>

                        <div class="col-md-2 my-2">
                         <input type="number" v-model="size.dozen" @change="ItemUpdate(insertData, colorData, size)" min="1" class="form-control" placeholder="Dozen" required>
                        </div>

                        <div class="col-md-2 my-2">
                         <input type="number" step="0.01" @change="ItemUpdate(insertData, colorData, size)" v-model="size.price" class="form-control" placeholder="Price" min="1" required>
                        </div>

                        <div class="col-md-1 my-2">
                          <button v-if="id != 0" class="btn btn-danger" type="button" @click="moreItemRemove(insertData, colorData, colorData.sizes, id)">x</button>
                        </div>

                        <div class="col-md-1 my-2">
                          <span>{{size.box * (size.dozen * 12)}}</span>
                        </div>
                        <div class="col-md-2 my-2">
                          <span>{{((size.box * (size.dozen * 12)) * size.price).toLocaleString(undefined,{ minimumFractionDigits: 2 })}}</span>
                        </div>
                      </div>

                      <!-- {{colorData}} -->

                      <div class="row">
                        <div class="col-md-8">
                          <p class="text-right"><b>Total</b></p>
                          <hr>
                        </div>
                        <div class="col-md-2 text-right">
                          <b>{{colorData.qty}}</b>
                          <hr>
                        </div>
                        <div class="col-md-2">
                          <b>{{colorData.total.toLocaleString(undefined,{ minimumFractionDigits: 2 })}}</b>
                          <hr>
                        </div>
                      </div>

                    </div>

                    <div class="col-md-10 mx-3">
                        <div class="text-left">
                          <button class="btn btn-info btn-sm" type="button" @click="moreItem(colorData)">More Item +</button>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                    <div class="text-left">
                      <button class="btn btn-primary btn-sm my-2" type="button" @click="moreColor()">More Color +</button>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-8">
                          <p class="text-right"><b>Sub Total</b></p>
                          <hr>
                        </div>
                        <div class="col-md-2 text-right">
                          <b>{{insertData.qty}}</b>
                          <hr>
                        </div>
                        <div class="col-md-2">
                          <b>{{insertData.total.toLocaleString(undefined,{ minimumFractionDigits: 2 })}}</b>
                          <hr>
                        </div>
                      </div>
                </div>
          </div>
        </div>

      </div>
        <div class="text-right">
          <TheButton :disabled="$store?.state?.loadding" :text="'Save Change'" :loading="$store.state.loadding"></TheButton>
        </div>
      </form>
      <hr>

              </div>
            </div>
            </div>
          </div>
        </div>
  </div>

</template>

<script>

import { mapState } from 'vuex';

import TheButton from "../../helper/TheButton.vue";
import myAxios from "../../service/axios.js";

export default {
  data() {
      return {
        serials    : [],
        cloths     : [],
        colors     : [],
        sizes      : [],
        insertData : {
          serial_id : '',
          clothing_id : '',
          total : 0,
          qty : 0,
          colors : [{color_id : '', qty: 0, total : 0, sizes : [{size_id : '', box : '', dozen : '', price : '', qty: '', total : ''}]
                  }]
        }
      }
  },
  computed : {
    ...mapState('shipping', {
      dataList : state => state.dataList
    }),
  },
  methods: {
    ItemUpdate(main, parent = null, child = null){
      if(parent != null && child != null){
        let qty   = 0;
        let price = 0;
        parent.sizes.forEach((item) => {
        qty   += (item.box*(item.dozen*12));
        price += (item.box*(item.dozen*12) * item.price);
        });

        child.qty     = (child.box * (child.dozen * 12))
        child.total   = child.qty * child.price;
        parent.qty    = qty;
        parent.total  = price;
      }


      let subTotal  = 0;
      let subQty    = 0;
      main.colors.forEach((color) => {
        subQty += color.qty;
        subTotal += color.total;
      });
      main.qty    = subQty;
      main.total  = subTotal;
    },
    async serialList(){
      try{
        await myAxios
        .get("admin/getSerial", {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem('accessToken')
          }
        })
        .then((response) => {
          this.serials = response.data.data
        })
      }catch(err){
        console.log(err)
      }
    },
    async clothinglist(){
      try{
        await myAxios
        .get("admin/getClothing", {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem('accessToken')
          }
        })
        .then((response) => {
          this.cloths = response.data.data
        })
      }catch(err){
        console.log(err)
      }
    },    
    async colorlist(){
      try{
        await myAxios
        .get("admin/getColor", {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem('accessToken')
          }
        })
        .then((response) => {
          this.colors = response.data.data
        })
      }catch(err){
        console.log(err)
      }
    },    
    async sizelist(){
      try{
        await myAxios
        .get("admin/getSize", {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem('accessToken')
          }
        })
        .then((response) => {
          this.sizes = response.data.data
        })
      }catch(err){
        console.log(err)
      }
    },
    moreColor(){
      this.insertData.colors.push({color_id : '', qty: 0, total : 0, sizes : [{size_id : '', box : '', dozen : '', price : ''}]});
    },    
    moreItem(item){
      item.sizes.push({size_id : '', box : '', dozen : '', price : '', qty: 0, total : 0});
    },    
    moreItemRemove(main, parent, item, index){
      item.splice(index, 1);
      this.ItemUpdate(main, parent, item);
    },
    insert(){
      this.$store.dispatch("shipment/insert", this.insertData);
      this.insertData = {
          serial_id : '',
          clothing_id : '',
          total : 0,
          qty : 0,
          colors : [{color_id : '', qty: 0, total : 0, sizes : [{size_id : '', box : '', dozen : '', price : '', qty: '', total : ''}]
                  }]
        }
        this.toastr.success('Order place successfully.');
    },
    addMoreRemove(main, item, index){
      item.splice(index, 1);
      this.ItemUpdate(main);
    },
  },
  mounted(){
    this.serialList();
    this.clothinglist();
    this.colorlist();
    this.sizelist();
  },
  components : {
    TheButton
  },
  created(){
    document.title = "Shipping Create"
  }
};
</script>
