<template>
	<div class="col-md-10">
        <div class="m-2 my-4">
          <div class="card my-2">
            <div class="card-body">
            <div style="overflow: hidden;">
              <h3 class="float-left">Shipment List</h3>
							<h3 class="float-right">
								<router-link :to="{name : 'ShipmentPage'}" class="btn btn-primary">Add New</router-link>
							</h3>
            </div>
            <hr>

						<DataTable :data="dataList" :columns="columnss" class="table table-responsive-sm table-striped">
							<thead>
								<tr>
									<th>SL</th>
									<th>Name</th>
									<th>Qty</th>
									<th>Total</th>
									<th>Action</th>
								</tr>
							</thead>

						<template #column-4="props">

						<Button class="btn btn-info btn-sm mr-2"
						@click="redirectSlug(props.rowData)"
						><i class="fa fa-eye"></i></Button>

						<Button class="btn btn-primary btn-sm mr-2"
						@click="edit(props.rowData)"
						><i class="fa fa-edit"></i></Button>

						<Button class="btn btn-danger btn-sm"
						@click="remove(props.rowData)"
						><i class="fa fa-trash"></i></Button>
						</template>
						</DataTable>
						<div class="loader" v-if="$store.state.loadding"></div>
            </div>
          </div>
        </div>
  </div>


</template>

<script>

import { mapState } from 'vuex';
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import  'datatables.net-bs4';
DataTable.use(DataTablesCore);


export default {
  data() {
			return {
				columnss : [
						{data : null, render : function(data, type, row, meta){ return `${meta.row + 1}`}},
						{data : "name"},
						{data : null, render : function(data){
							return data.qty;
						}},
						{data : null, render : function(data){
							return data.total.toLocaleString(undefined,{ minimumFractionDigits: 2 });
						}},
				],
				insertData : [{name : ''}],
				updateData : {id : '',name : '', status : ''}
			}
  },
	computed : {
		...mapState('shipment', {
			dataList : (state) => {
				return state.dataList.map((data) => {
					let total = 0;
					let qty  	= 0;
					data.manage_clothing.forEach(itemData => {
						qty     += itemData.qty;
						total 	+= parseInt(itemData.total);
					});
					return {
						id: data.id,
						name: data.name,
						status: data.status,
						total,
						qty
					}
				});
			}
		})
	},
  methods: {
		redirectSlug(data){
			this.$router.push({name : "ShipmentDetails", params : {slug : data.name}});
		},
		edit(){
			this.$router.push({name: 'ShipmentEdit'});
		},
		update() {
			this.$store.dispatch("shipment/update", this.updateData);
			this.updateData.avatar 	= "";
		},
		remove(data) {
			if(confirm("All payment, style, color, size delete?")){
				this.$store.dispatch("shipment/remove", data);
			}
		},
  },
	mounted(){
		this.$store.dispatch("shipment/getData");
	},
	components : {
		DataTable
	},
	created(){
    document.title = "Shipment List"
  }
};
</script>
