import { createStore} from "vuex";
import myAxios from "../service/axios.js";
import login from "./modules/login.js";
import router from "../router.js";
import usertype from "./modules/usertype.js";
import setup from "./modules/setup.js";
import color from "./modules/color.js";
import size from "./modules/size.js";
import serial from "./modules/serial.js";
import clothing from "./modules/clothing.js";
import shipment from "./modules/shipment.js";
import payment from "./modules/payment.js";
import passwordRecovery from "./modules/passwordRecovery.js";

const store = createStore({
	modules : {
		login,
		usertype,
		setup,
		color,
		size,
		serial,
		clothing,
		shipment,
		payment,
		passwordRecovery
	},
	state(){
		return {
			loadding : false,
			modalLoading : false,
			users : [],
			errors : [],
			siteData : []
		}
	},
	getters : {},
	mutations : {
		loadding(context, payload){
			context.loadding = payload;
		},
		users(context, payload){
			context.users = payload;
		},
		errors(context, payload){
			context.errors = payload;
		},
		modalLoading(context, payload){
			context.modalLoading = payload;
		},
		autologout(){
			router.push({name : "login"});
			localStorage.removeItem("accessToken");
		}
	},
	actions : {
		async setSettingsData(context){
			try{
				await myAxios
				.get("admin/settings",{
					headers : {
						"Authorization" : "Bearer "+localStorage.getItem('accessToken')
					}
				})
				.then((response) => {
					context.state.siteData = response.data.data;
				})
			}catch(err){
				//err
			}
		},		
		async siteDataUpdate(context, payload){

			context.commit('loadding', true, {root: true});
			try{
				await myAxios
				.post("admin/createSettings", payload.data, {
					headers : {
						"Authorization" : "Bearer "+localStorage.getItem('accessToken'),
						"Content-Type" : "multipart/form-data"
					}
				})
				.then((response) => {
					context.commit('loadding', false, {root: true});
					context.state.siteData = response.data.data;
					payload.myModal.modal("hide");
				})

			}catch(err){
				context.commit('loadding', false, {root: true});
				if(err?.response?.data?.message == "Unauthenticated."){
					context.commit("autologout");
				}
			}
		},
		async setUserData(context){
			try{
				await myAxios
				.get("user",{
					headers : {
						"Authorization" : "Bearer "+localStorage.getItem('accessToken')
					}
				})
				.then((response) => {
					context.state.users = response?.data?.data;
				})
			}catch(err){
				context.commit('loadding', false, {root: true});
				if(err?.response?.data?.message == "Unauthenticated."){
					context.commit("autologout");
				}
			}
		},
		async logout(context, payload){
			try{
				await myAxios
				.delete("admin/deleteUniversity", {
					params : {
						id : payload.id
					},
					headers : {
						"Authorization" : "Bearer "+localStorage.getItem('accessToken')
					}
				})
				.then(() => {
					context.dispatch('getData');
				})
			}catch(err){
				context.commit('loadding', false, {root: true});
				if(err.code == "ERR_NETWORK"){
					context.commit('errors', {network_error : err.message}, {root: true});
				}else{
					context.commit('errors', err.response.data.errors, {root: true});
				}
				if(err.response.data.message == "Unauthenticated."){
					context.commit("autologout");
				}
			}
		},
		async updateProfile(context, payload){
			context.commit('loadding', true, {root: true});
			try{
				await myAxios
				.post("updateProfile", payload, {
					headers : {
						"Authorization" : "Bearer "+localStorage.getItem('accessToken'),
						"Content-Type" : "multipart/form-data"
					}
				})
				.then(() => {
					context.commit('loadding', false, {root: true});
					context.dispatch('setUserData');
				})
			}catch(err){
				context.commit('loadding', false, {root: true});
				if(err.code == "ERR_NETWORK"){
					context.commit('errors', {network_error : err.message}, {root: true});
				}else{
					context.commit('errors', err.response.data.errors, {root: true});
				}
				if(err.response.data.message == "Unauthenticated."){
					context.commit("autologout");
				}
			}
		},
		async passwordUpdate(context, payload){
			context.commit('loadding', true, {root: true});
			try{
				await myAxios
				.put("changePassword", payload, {
					headers : {
						"Authorization" : "Bearer "+localStorage.getItem('accessToken')
					}
				})
				.then((response) => {
					context.commit('loadding', false, {root: true});
					context.commit('errors', response?.data?.data, {root: true});
					context.dispatch('setUserData');
				})
			}catch(err){
				context.commit('loadding', false, {root: true});
				if(err.code == "ERR_NETWORK"){
					context.commit('errors', {network_error : err.message}, {root: true});
				}else{
					context.commit('errors', err.response.data.errors, {root: true});
				}
				if(err?.response?.data?.message == "Unauthenticated."){
					context.commit("autologout");
				}
				if(err?.response?.data?.data){
					context.commit('errors', err.response.data.data, {root: true});
				}
			}
		}
	}
});

export default store;