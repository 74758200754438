<template>
	<div>
		<div style="margin: 0 auto;text-align:center">
			<h2 style="font-size: 24px; margin: 5px;">{{$store?.state?.siteData?.title}}</h2>
			<h4 style="font-size: 18px;margin: 5px;padding-bottom: 5px;">{{$store?.state?.siteData?.address}}</h4>
			<h4 style="font-size: 18px;margin: 5px;padding-bottom: 5px;">Phone: {{$store?.state?.siteData?.phone}}, Email: {{$store?.state?.siteData?.email}}</h4>

		</div>
		<h4>Serial No: {{shipmentDatas?.data?.name}}</h4>
		<table style="width: 100%" border="1" cellpadding="10" cellspacing="0">
			<tr>
				<th width="60%" colspan="3">Information</th>
				<th width="20%">Total Quantity</th>
				<th width="20%">Total Value</th>
			</tr>
			<tr v-for="(shipmentData, id) in shipmentDatas?.data?.manage_clothing" :key="id">
				<td colspan="3"><b>{{shipmentData.clothing.name}}</b> <b>total value and quantity</b></td>
				<td><b>{{shipmentData.qty}}</b></td>
				<td><b>{{parseInt(shipmentData.total).toLocaleString(undefined,{ minimumFractionDigits: 2 })}}</b></td>
			</tr>
			<tr>
				<td colspan="3" align="right"><b>Total</b></td>
				<td><b>{{shipmentDatas?.qty}}</b></td>
				<td><b>{{parseInt(shipmentDatas?.total).toLocaleString(undefined,{ minimumFractionDigits: 2 })}}</b></td>
			</tr>
			<tr v-for="(payment, id) in payments" :key="id">
				<td>
					<p class="m-0 p-0" align="center"><b>{{payment.date}}</b></p>
					<p class="m-0 p-0" align="center"><b>{{payment.date}}</b></p>
				</td>
				<td>
					<p class="m-0 p-0"><b>{{payment.payment_type}}</b></p>
					<p class="m-0 p-0" align="right"><b>Balance</b></p>
				</td>
				<td>
					<p class="m-0 p-0" align="center"><b>{{payment.qty_details}}</b></p>
				</td>
				<td>
					<p class="m-0 p-0" align="right"><b>{{parseInt(payment.paid_amount).toLocaleString(undefined,{ minimumFractionDigits: 2 })}}</b></p>
					<p class="m-0 p-0" align="left"><strong>
						<span v-if="payment.balance == 0">Nil</span>
						<span v-else>{{payment.balance.toLocaleString(undefined,{ minimumFractionDigits: 2 })}}</span>
					</strong></p>
				</td>
				<td></td>
			</tr>
		</table>
	</div>
</template>

<script>
export default {

  name: 'PaymentPrint',

  data () {
    return {

    }
  },
  props : ['shipmentDatas', 'payments']
}
</script>

<style lang="css" scoped>
</style>