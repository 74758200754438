import myAxios from "../../service/axios.js";
export default {
	namespaced : true,
	state(){
		return {
			dataList : []
		}
	},
	getters : {},
	mutations : {},
	actions : {
		async getData(context){
			try{
				context.commit('loadding', true, {root: true});
				await myAxios
				.get("admin/getClothing", {
					headers : {
						"Authorization" : "Bearer "+localStorage.getItem('accessToken')
					}
				})
				.then((response) => {
					context.state.dataList = response?.data?.data;
					context.commit('loadding', false, {root: true});
				})
			}catch(err){
				context.commit('loadding', false, {root: true});
				if(err.code == "ERR_NETWORK"){
					context.commit('errors', {network_error : err.message}, {root: true});
				}else{
					context.commit('errors', err.response.data.errors, {root: true});
				}
				if(err?.response?.data.message == "Unauthenticated."){
					context.commit("autologout",{}, {root: true});
				}
			}
		},
		async insert(context, payload){
			try{
				let response = "";
				context.commit('loadding', true, {root: true});
				await myAxios
				.post("passwordrecovery", payload)
				.then((res) => {
					context.commit('loadding', false, {root: true});
					response = res;
				})
				return response.data;
			}catch(err){
				context.commit('loadding', false, {root: true});
				return err.response.data;
			}
		},		
		async linkcheck(context, payload){
			try{
				let response = "";
				context.commit('loadding', true, {root: true});
				await myAxios
				.post("linkcheck", payload)
				.then((res) => {
					context.commit('loadding', false, {root: true});
					response = res;
				})
				return response.data;
			}catch(err){
				context.commit('loadding', false, {root: true});
				return err.response.data;
			}
		},
		async update(context, payload){
			try{
				let response = "";
				context.commit('loadding', true, {root: true});
				await myAxios
				.post("passwordUpdate", payload)
				.then((res) => {
					context.commit('loadding', false, {root: true});
					response = res;
				})
				return response.data;
			}catch(err){
				context.commit('loadding', false, {root: true});
				return err.response.data;
			}
		}
	}
}