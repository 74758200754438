<template>
	<div class="col-md-2 bg-dark" :style="{'min-height' : sHeight+'px'}">
      <div id="accordian">
        <ul class="list-unstyled side-menu my-3">
              <li class="p-1 tree">
                <router-link activeClass="active-menu" :to="{name : 'dashboard'}"><i class="fa fa-home"></i> Home</router-link>
              </li>
              <li class="p-1 tree">
                <a href="#select-10" data-toggle="collapse"><i class="fa fa-file-text" aria-hidden="true"></i>Shipment</a>
                  <ul class="collapse list-unstyled ml-4" :class="{'show' : $route.name == 'ShipmentList' || $route.name == 'ShipmentPage' || $route.name == 'ShipmentDetails' || $route.name == 'PaymentPage' || $route.name == 'ShippingReport' || $route.name == 'ShipmentEdit' }" id="select-10" data-parent="#accordian">
                      <li class="p-1 tree">
                        <router-link activeClass="active-menu" :to="{name : 'ShipmentPage'}">Create New</router-link>
                      </li>
                      <li class="p-1 tree">
                        <router-link activeClass="active-menu" :to="{name : 'ShipmentList'}">Shipment List</router-link>
                      </li>
                      <li class="p-1 tree">
                        <router-link activeClass="active-menu" :to="{name : 'PaymentPage'}">Payment History</router-link>
                      </li>
                      <li class="p-1 tree">
                        <router-link activeClass="active-menu" :to="{name : 'ShippingReport'}">Generate Report</router-link>
                      </li>
                  </ul>
              </li>
              <li class="p-1 tree">
                <a href="#select-9" data-toggle="collapse"><i class="fa fa-cogs" aria-hidden="true"></i>Configuration</a>
                  <ul class="collapse list-unstyled ml-4" :class="{'show' : $route.name == 'SerialPage' || $route.name == 'ClothingPage' || $route.name == 'ShippingPage' || $route.name == 'ColorPage' || $route.name == 'SizePage' }" id="select-9" data-parent="#accordian">
                      <li class="p-1 tree">
                        <router-link activeClass="active-menu" :to="{name : 'SerialPage'}">Serial List</router-link>
                      </li>
                      <li class="p-1 tree">
                        <router-link activeClass="active-menu" :to="{name : 'ClothingPage'}">Style List</router-link>
                      </li>
                      <li class="p-1 tree">
                        <router-link activeClass="active-menu" :to="{name : 'ColorPage'}">Color List</router-link>
                      </li>
                      <li class="p-1 tree">
                        <router-link activeClass="active-menu" :to="{name : 'SizePage'}">Size List</router-link>
                      </li>
                  </ul>
              </li>
              <li class="p-1 tree">
                <a href="#select-11" data-toggle="collapse"><i class="fa fa-users" aria-hidden="true"></i>Users</a>
                  <ul class="collapse list-unstyled ml-4" :class="{'show' : $route.name == 'adminlist' }" id="select-11" data-parent="#accordian">
                      <li class="p-1 tree">
                        <router-link activeClass="active-menu" :to="{name : 'adminlist'}">Admin List</router-link>
                      </li>
                  </ul>
              </li>
              <li class="p-1 tree">
                <a href="#" @click.prevent="edit()"><i class="fa fa-cog" aria-hidden="true"></i>Settings</a>
              </li>
        </ul>
      </div>
  </div>


  <!-- Insert modal -->
<div class="modal fade" id="updateSettings" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Settings</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
      <form enctype="multipart/form-data" @submit.prevent="insert">
        <p class="text-center text-danger" v-if="$store?.state?.errors?.network_error">{{$store.state.errors.network_error}}</p>
        <div class="row">
          <div class="col-md-6 my-2">
            <input type="text" class="form-control" v-model="settings.title" placeholder="Site Name">
          </div>
          <div class="col-md-6 my-2">
            <input type="text" class="form-control" v-model="settings.phone" placeholder="Phone">
          </div>
          <div class="col-md-6 my-2">
            <input type="text" class="form-control" v-model="settings.email" placeholder="Email">
          </div>
          <div class="col-md-6 my-2">
            <input type="text" name="name" class="form-control" v-model="settings.address" placeholder="Address">
          </div>
          <div class="col-md-12 my-2">
            <textarea class="form-control" v-model="settings.map" placeholder="Google Map"></textarea>
          </div>
          <div class="col-md-6 my-2">
            <input type="file" class="form-control-file" @change="image($event, 1)" title="This is site logo.">
          </div>
        </div>
        <div class="text-right">
          <TheButton :disabled="$store?.state?.loadding" :text="'Save Change'" :loading="$store.state.loadding"></TheButton>
        </div>
      </form>
      </div>
    </div>
  </div>
</div>

</template>

<script>

import TheButton from "../../../helper/TheButton.vue";
import { onMounted } from 'vue';
import $ from 'jquery';
import 'bootstrap';

let updateModal = null;

export default {
  name: 'SideBarPage',
  setup(){
    onMounted(()=>{
      updateModal = $("#updateSettings");
    })
  },
  data () {
    return {
      sHeight : 0,
      settings : {title : '',phone : '', email : '', address : '', map : '', logo : '', favicon : ''}
    }
  },
  methods : {
    image(event, index){
      if(index == 1){
        this.settings.logo    = event.target.files[0] ? event.target.files[0] : "";
      }else if(index == 2){
        this.settings.favicon = event.target.files[0] ? event.target.files[0] : "";
      }
    },
    edit(){
      updateModal.modal("show");
      this.settings.title      = this.$store?.state?.siteData?.title;
      this.settings.phone      = this.$store?.state?.siteData?.phone;
      this.settings.email      = this.$store?.state?.siteData?.email;
      this.settings.address    = this.$store?.state?.siteData?.address;
      this.settings.map        = this.$store?.state?.siteData?.map;
      this.settings.logo       = "";
      this.settings.favicon    = "";
    },
    insert(){
      this.$store.dispatch("siteDataUpdate", {data : this.settings, myModal : updateModal});
    },
    settingsDataLoad(){
      this.$store.dispatch('setSettingsData');
    }
  },
  mounted(){

    if(window.innerWidth <= 425){
      this.sHeight = 0
    }else{
      this.sHeight = window.innerHeight - 120
    }
    this.settingsDataLoad();
  },
  components : {
    TheButton
  }
}
</script>

<style lang="css" scoped>
  .active-menu{
    color: #ddd8d8 !important;
    font-size : 20px;
  }
</style>