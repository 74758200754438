<template>
  <div class="col-md-10">
        <div class="m-2 my-4">
          <div class="card my-2">
            <div class="card-body">
            <div style="overflow: hidden;">
              <h3 class="float-left">Generate Report</h3>
            </div>
            <hr>
			<form @submit.prevent="search">
				<div class="row">
					<div class="col-md-4 my-2">
						<select class="form-control" v-model="serial_id" required @change="getSelectSerialData">
							<option value="">Select Serial</option>
							<option :value="serial.id" v-for="serial in serials" :key="serial.id">{{serial.name}}</option>
						</select>
					</div>
					<div class="col-md-4 my-2">
						<select class="form-control" v-model="cloth_id" required>
							<option value="">Select One</option>
							<option :value="cloth?.clothing?.id" v-for="cloth in cloths" :key="cloth?.id">
								{{cloth?.clothing?.name}}
							</option>
						</select>
					</div>
					<div class="col-md-4 my-2">
						<button class="btn btn-primary">Generate</button>
					</div>
				</div>
			</form>

			<hr>
			<div style="overflow: hidden;" v-if="finalData?.clothing">
              <h5 class="float-left">{{finalData?.clothing?.name}}</h5>
							<h3 class="float-right">
								<button class="btn btn-primary" type="button" @click="print">Print</button>
							</h3>
      </div>


			<div class="ml-2" v-for="mc in finalData.manage_color" :key="mc">
				<h6>{{mc.color.name}}</h6>
				<table class="table table-striped table-responsive-sm table-bordered">
					<tr>
						<th width="70%" style="padding: 0;">
							<table class="table m-0 p-0">
								<tr>
									<th v-for="ms in mc.manage_size" :key="ms" style="margin: 0; padding: 0">
										<p align="center" style="background: rgb(99 138 199);color: rgb(36 25 29);" class="m-0 p-0">{{ms.size.name}}</p>
										<p align="center" style="background: rgb(195 183 203);color: rgb(36 25 29);" class="m-0 p-0">{{ms.qty}} x {{(ms.total / ms.qty).toLocaleString(undefined,{ minimumFractionDigits: 2 })}}</p>
										<p align="center" style="background: rgb(225 206 210);color: rgb(36 25 29);" class="m-0 p-0">{{ms.total}}</p>
									</th>
								</tr>
							</table>
						</th>
						<th width="15%" style="padding: 0;">
							<p align="center" style="background: rgb(99 138 199);color: rgb(36 25 29);" class="m-0 p-0">Total Value</p>
							<p align="center" style="background: rgb(195 183 203);color: rgb(36 25 29);" class="m-0 p-0">{{mc.total}}</p>
						</th>
						<th width="15%" style="padding: 0;">
							<p align="center" style="background: rgb(99 138 199);color: rgb(36 25 29);" class="m-0 p-0">Total Qty</p>
							<p align="center" style="background: rgb(195 183 203);color: rgb(36 25 29);" class="m-0 p-0">{{mc.qty}}</p>
						</th>
					</tr>
				</table>
			</div>

		<div class="ml-2" v-if="finalData?.total">
				<table class="table table-responsive-sm table-bordered">
					<tr style="background: #cdcdcd;color: #7c3838;">
						<th width="80%">
							<p align="right" class="m-0 p-0">Total</p>
						</th>
						<th width="10%">
							<p align="center" class="m-0 p-0">{{finalData.total}}</p>
						</th>
						<th width="10%">
							<p align="center" class="m-0 p-0">{{finalData.qty}}</p>
						</th>
					</tr>
				</table>
		</div>


            <div class="loader" v-if="$store.state.loadding"></div>
            </div>
          </div>
        </div>
        <!-- {{finalData}} -->
        <hr>

        <StylePrint :finalData="finalData" v-show="false" ref="stylePrintData"></StylePrint>

  </div>


</template>

<script>

import { mapState } from 'vuex';
import myAxios from "../../service/axios.js";
import StylePrint from './print/StylePrint.vue';

export default {
  data() {
      return {
        serials 		: [],
        cloths  		: [],
        serial_id  	: '',
        cloth_id  	: '',
        serialData  : [],
        finalData		: []
      }
  },
  computed : {
    ...mapState('payment', {
      dataList : state => state.dataList
    })
  },
  methods: {
		print(){
			const w = window.open("", "_blank", "width=1000, height=650");
			w.document.write(this.$refs.stylePrintData.$el.innerHTML);
			w.document.close();
			w.print();
		},
    async serialList(){
      try{
        await myAxios
        .get("admin/getSerial", {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem('accessToken')
          }
        })
        .then((response) => {
          this.serials = response.data.data
        })
      }catch(err){
        console.log(err)
      }
    },
    async getSelectSerialData(e){
			if(e.target.value == ""){
				this.cloth_id = "";
				return false;
			}

			this.cloth_id = "";

			try{
			await myAxios
			.get("admin/getProcessing", {
			headers : {
				"Authorization" : "Bearer "+localStorage.getItem('accessToken')
			},
			params : {
				serial_id : e.target.value
			}
			})
			.then((response) => {
				this.serialData = response.data;
				this.cloths 		= response.data.manage_clothing;
			})
			}catch(err){
				console.log(err)
			}
    },
    async search(){
		this.finalData = this.serialData.manage_clothing.find((item) => this.cloth_id == item.clothing.id);
    }
  },
  mounted(){
    this.serialList();
    this.$store.dispatch("payment/getData");
  },
  components : {
		StylePrint
  },
  created(){
    document.title = "Shipping Report"
  }
};
</script>